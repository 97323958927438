// ** React Imports
import { useCallback, useEffect, useRef, useState } from 'react';
import { Label, Button } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';

const RangePicker = ({
  name,
  id,
  label,
  autoClose = false,
  className = '',
  autoApply = false,
  handleDateChange = () => false,
  ...props
}) => {

  let datepickerRef = useRef(null);
  const [dates, setDates] = useState([new Date(), new Date()]);

  const closeDatepicker = () => {
    setDates([new Date(), new Date()]);
    if (datepickerRef.current) {
      datepickerRef.current.setOpen(false);
    }
  };

  const applyDatepicker = useCallback(() => {
    let startDate = moment(dates[0]).startOf('day').format('YYYY-MM-DD');
    let endDate = moment(dates[1]).endOf('day').format('YYYY-MM-DD');
    //
    console.log(startDate, endDate);
    handleDateChange([startDate, endDate]);
    //
    if(datepickerRef.current) {
      datepickerRef.current.setOpen(false);
    }
  }, [dates, handleDateChange, datepickerRef]);


  const onChange = useCallback((dates) => {
    setDates(dates);
  }, []);

  useEffect(() => {
    if (autoApply) {
      applyDatepicker();
    }
  }, [autoApply]);

  return (
    <div className="cursor-pointer">
      {label && (
        <Label className="form-label" HtmlFor={id}>
          {label}
        </Label>
      )}
      <DatePicker
        {...props}
        name={name}
        id={id}
        className={`form-control cursor-pointer ${className}`}
        //selected={dates[0]}
        startDate={dates[0]}
        endDate={dates[1]}
        onChange={onChange}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        dateFormat="dd MMM y"
        selectsRange
        selectsDisabledDaysInRange
        shouldCloseOnSelect={autoClose}
        ref={datepickerRef}
        onClickOutside={(e) => {
          if (datepickerRef.current) {
            datepickerRef.current.setFocus(true)
          }
        }}>
        {!autoApply && (
          <>
            <div className="clearfix"></div>
            <div className="custom-flex-row justify-content-between align-items-center w-100">
              <Button.Ripple
                color="relief-primary"
                className="flex-2-col rounded-0 apply-dp"
                onClick={() => {
                  closeDatepicker();
                }}>
                Cancel
              </Button.Ripple>
              <Button.Ripple
                color="relief-primary"
                className="flex-2-col rounded-0 close-dp"
                onClick={() => applyDatepicker()}>
                Apply
              </Button.Ripple>
            </div>
          </>
        )}
      </DatePicker>
    </div>
  );
};

export default RangePicker;
