import { createSlice } from "@reduxjs/toolkit"

export const maintenanceScheduleSlice = createSlice({
  name: 'maintenanceSchedule',
  initialState: {
    data: []
  },
  reducers: {
    addMaintenanceSchedulePeriod: (state, action) => {
      const { fire_sys_type, period } = action.payload;
      let systemIndex = state.data.findIndex(item => item.fire_sys_type === fire_sys_type);
      if (systemIndex === -1) {
        // If the fire system doesn't exist, create it
        state.data.push({ fire_sys_type, schedules: [] });
        systemIndex = state.data.length - 1;
      }
      const system = state.data[systemIndex];
      
      if (!system.schedules?.find(schedule => schedule.type === period)) {
        system.schedules.push({ type: period, columns: [] });
      }
      
    },
    removeMaintenanceSchedulePeriod: (state, action) => {
      const { fire_sys_type, period } = action.payload;
      const systemIndex = state.data.findIndex(item => item.fire_sys_type === fire_sys_type);
  
      if (systemIndex > -1) {
        state.data[systemIndex].schedules = state.data?.[systemIndex]?.schedules?.filter(schedule => schedule.type !== period);
        
        // If no schedules left, remove the fire system type
        if (state.data[systemIndex].schedules.length === 0) {
          state.data.splice(systemIndex, 1);
        }
      } 
    },
    addMaintenanceSchedulePeriodColumn: (state, action) => {
      const { fire_sys_type, period, id } = action.payload;
      const system = state.data.find(item => item.fire_sys_type === fire_sys_type);
      if (system) {
        const schedule = system.schedules.find(s => s.type === period);
        if (schedule && !schedule.columns.includes(id)) {
          schedule.columns.push(id);
        }
      }
    },
    removeMaintenanceSchedulePeriodColumn: (state, action) => {
      const { fire_sys_type, period, id } = action.payload;
      const system = state.data.find(item => item.fire_sys_type === fire_sys_type);
      if (system) {
        const schedule = system.schedules.find(s => s.type === period);
        if (schedule) {
          schedule.columns = schedule.columns.filter(col => col !== id);
        }
      }
    },
    resetMaintenanceSchedulePeriodColumn: (state, action) => {
      const { fire_sys_type, period } = action.payload;
      const system = state.data.find(item => item.fire_sys_type === fire_sys_type);
      if (system) {
        const schedule = system.schedules.find(sch => sch.type === period);
        if (schedule) {
          schedule.columns = [];
        }
      }
    },
    resetMaintenanceScheduleData: (state, action) => {
      state.data = Array.isArray(action.payload) ?  action.payload : [];
    }
  }
});

export const { 
  addMaintenanceSchedulePeriod,
  removeMaintenanceSchedulePeriod,
  addMaintenanceSchedulePeriodColumn,
  removeMaintenanceSchedulePeriodColumn,
  resetMaintenanceSchedulePeriodColumn,
  resetMaintenanceScheduleData
} = maintenanceScheduleSlice.actions

export default maintenanceScheduleSlice.reducer;