// ** Reducers Imports
import NiceModal from "@ebay/nice-modal-react";
import navbar from "./navbar";
import layout from "./layout";
import sidebar from "./sidebar";

import appUsers from "@dashboard/User/Profile/ViewProfile/store";
import dynamicTabs from "@components/DynamicTabs/store";
//User Reducers
import loginUser from "@userAuth/Login/store";

import helpers from "./actions/Helpers";
import toastify from "./actions/Notify";

import modalReducer from "@components/Modals/store";

import systemTypes from "@dashboard/WorkSector/SystemConfigurations/DropdownLists/store";
import systemLists from "@components/ReduxComponents/SingleSelect/store";

//Work Sector
import coreForm from "@components/Form/store";
import company from "@views/Auth/Company/Login/store";
import tasks from "@src/views/PersonalSector/Tasks/store";
import preventive from "@src/views/WorkSector/MaintenancActions/PreventiveMaintenance/Table/store"
import maintenanceSchedule from "@src/views/WorkSector/InspectionSchedule/MaintenanceSchedule/List/store/index"
import workCalendarSlice from "@src/views/WorkSector/WorkCalendar/store";

const nicemodals = NiceModal.reducer;

const rootReducer = {
  nicemodals,
  toastify,
  helpers,
  navbar,
  layout,
  sidebar,
  loginUser,
  modalReducer,
  appUsers,
  systemTypes,
  systemLists,
  dynamicTabs,
  preventive,
  maintenanceSchedule,
  workCalendarSlice,
  coreForm,
  company,
  tasks,
};

export default rootReducer;
