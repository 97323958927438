import { createSlice } from "@reduxjs/toolkit"

export const preventiveSlice = createSlice({
  name: 'preventive',
  initialState: {
    data: {}
  },
  reducers: {
    updatePreventiveStateData: (state, action) => {
      state.data = {...action.payload};
    },
    updatePreventiveValues: (state, action) => {
      let data = {...state.data};
      const { id, values } = action.payload;
      if (!data[id]) {
        data[id] = {};
      }
      //
      values.forEach(({key, value}) => {
        data[id][key] = value;
      });
      //
      state.data = data;
    },
    updateColumnStatus: (state, action) => {
      const { id, column, value } = action.payload;
      let oldState = { ...state.data };

      if (!oldState[id]) {
        oldState[id] = { columns: {} };
      }

      if (!oldState[id].columns) {
        oldState[id].columns = {};
      }

      if (value != undefined) {
        oldState[id].columns[column] = value;
      } else {
        delete oldState[id].columns[column];
      }

      state.data = oldState;
    },
    
  },
  extraReducers: (builder) => {
    // Add extra reducers here if needed
  },
});

export const { 
  updatePreventiveStateData, 
  updatePreventiveValues, 
  updateColumnStatus, 
} = preventiveSlice.actions

export default preventiveSlice.reducer;